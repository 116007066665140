export const defaultCarModelObject = () => ({
  code: null,
  name: null,
  companies_car_manufacturer_ids: [],
  capacity: null,
  displacement: null,
  loading_capacity: null,
  size: {
    length: null,
    width: null,
    height: null
  },
  fuel_efficiency: null
})
