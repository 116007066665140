<template lang="pug">
  .car-model-form
    .arrow-back(@click="$emit('back')")
      FaIcon(
        class="fa-rotate-270"
        :icon="'chevron-up'"
      )
      span {{ $t("company_system.back") }}
    .title
      span {{ $t("company_system.car_class_settings.car_model_form.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="carModel.code"
            :placeholder="$t('company_system.car_class_settings.car_model_form.code')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            v-model="carModel.name"
            :class="{ invalid: $v.carModel.name.$error }"
            :placeholder="$t('company_system.car_class_settings.car_model_form.name')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.brand')")
        .form-row-field
          AppDropdown(
            searchable
            multiple
            removable-labels
            close-on-select
            allow-empty
            value-key="id"
            title-key="name"
            :value="selectedCarManufacturers"
            :items="translatedCarManufacturers"
            @select="selectCarManufacturers"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.passenger_capacity')")
        .form-row-field.riding-capacity.flex-row
          AppNumberInput(
            type="text"
            name="riding-capacity"
            :class="{ invalid: $v.carModel.capacity.$error }"
            v-model="carModel.capacity"
          )
          .label
            span {{ $t('company_system.persons') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.engine_capacity')")
        .engine-capacity.flex-row
          .form-row-field.engine-capacity-field.flex-row
            AppNumberInput(
              type="text"
              name="displacement"
              :max="9999"
              :class="{ invalid: $v.carModel.displacement.$error }"
              v-model="carModel.displacement"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_model_form.cubic_centimeters') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.loading_capacity')")
        .loading-capacity.flex-row
          .form-row-field.loading-capacity-field.flex-row
            AppNumberInput(
              type="text"
              name="loading-capacity"
              :max="99999"
              :class="{ invalid: $v.carModel.loading_capacity.$error }"
              v-model="carModel.loading_capacity"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_model_form.kilograms') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.size')")
        .car-size.flex-row
          .form-row-field.car-size-field.flex-row
            AppNumberInput(
              type="text"
              name="car-length"
              :max="99999"
              v-model="carModel.size.length"
            )
            .label
              span x
          .form-row-field.car-size-field.flex-row
            AppNumberInput(
              type="text"
              name="car-width"
              :max="99999"
              v-model="carModel.size.width"
            )
            .label
              span x
          .form-row-field.car-size-field.flex-row
            AppNumberInput(
              type="text"
              name="car-height"
              :max="99999"
              v-model="carModel.size.height"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_model_form.millimeters') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_model_form.fuel_efficiency')")
        .fuel-efficiency.flex-row
          .form-row-field.fuel-efficiency-field.flex-row
            AppInput(
              name="fuel-efficiency"
              :max="999.0"
              :class="{ invalid: $v.carModel.fuel_efficiency.$error }"
              v-model="carModel.fuel_efficiency"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_model_form.kilometers_per_liter') }}
    FormActionBar(
      :delete-enabled="isEdit"
      @delete="removeCarModel"
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { defaultCarModelObject } from "./helpers"
  import { extractTranslatedAttribute } from "@/helpers/common"
  import { map, filter, cloneDeep } from "lodash-es"

  // store modules
  import carModelSettingsModule from "@/config/store/company_system/car_class_settings/car_models"
  import carManufacturerSettingsModule from "@/config/store/company_system/car_class_settings/car_manufacturers"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withConfirmation from "@/mixins/withConfirmation"

  const carManufacturersMixin = withStoreModule(carManufacturerSettingsModule, {
    name: "carManufacturersMixin",
    readers: { carManufacturers: "items" },
    actions: { fetchCarManufacturers: "FETCH_ITEMS" }
  })

  const carModelSettingsMixin = withStoreModule(carModelSettingsModule, {
    resetState: true,
    name: "carModelSettingsMixin",
    readers: {
      carModelItem: "item"
    },
    actions: {
      fetchCarModel: "FETCH_ITEM",
      createCarModel: "CREATE_ITEM",
      updateCarModel: "UPDATE_ITEM",
      deleteCarModel: "DELETE_ITEM"
    },
    mutations: { setCarModel: "SET_ITEM" }
  })

  const validationsMixin = withValidations(({ required, minLength, integer, minValue }) => ({
    carModel: {
      name: {
        required,
        minLength: minLength(1)
      },
      capacity: {
        required,
        integer,
        minValue: minValue(1)
      },
      displacement: {
        required,
        integer,
        minValue: minValue(1)
      },
      loading_capacity: {
        required,
        integer,
        minValue: minValue(1)
      },
      fuel_efficiency: {
        required,
        minValue: minValue(1)
      }
    }
  }))

  export default {
    components: {
      FormActionBar: () => import("../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppInput: () => import("@/components/elements/AppInput"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [carManufacturersMixin, carModelSettingsMixin, validationsMixin, withConfirmation],

    props: {
      carModelId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        carModel: defaultCarModelObject()
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchCarModel(this.carModelId).then(() => {
          this.carModel = cloneDeep(this.carModelItem)
        })
      }
      this.fetchCarManufacturers()
    },

    methods: {
      selectCarManufacturers(carManufacturers) {
        this.carModel.companies_car_manufacturer_ids = map(carManufacturers, "id")
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("back")
        })
      },

      async sendRequest() {
        const { id, ...car_model } = this.carModel
        if (this.isEdit) {
          await this.updateCarModel({ id, car_model })
        } else {
          await this.createCarModel(car_model)
        }
      },

      removeCarModel() {
        this.$confirm({
          title: this.$t("company_system.car_class_settings.delete_car_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCarModel(this.carModelId).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      }
    },

    computed: {
      isEdit() {
        return !!this.carModelId
      },

      selectedCarManufacturers() {
        return filter(this.translatedCarManufacturers, ({ id }) =>
          this.carModel.companies_car_manufacturer_ids.includes(id)
        )
      },

      translatedCarManufacturers() {
        return map(this.carManufacturers, carManufacturer => {
          return {
            id: carManufacturer.id,
            name: extractTranslatedAttribute(carManufacturer, "name")
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .car-model-form
    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      .app-overlay-loader
        position: fixed

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          ::v-deep
            .app-number-input
              &.invalid
                input
                    +default-invalid-input
            input
              &.invalid
                +default-invalid-input

          .app-select
            padding: 0

        .riding-capacity
          ::v-deep
            input
              height: 38px
              width: 40px

          .label
            padding: 7px
            font-size: 15px

        .car-size
          &-field
            .label
              padding: 7px 10px
              font-size: 15px

            ::v-deep
              input
                height: 38px
                width: 65px

        .fuel-efficiency,
        .loading-capacity,
        .engine-capacity
          &-field
            .label
              padding: 7px
              font-size: 15px

            ::v-deep
              input
                font-size: 0.8rem
                text-align: center
                height: 38px
                width: 60px
</style>
